<template>  
  <div class="container">
    <div class="row">
      <div class="card col-md-12 mb-4">
        <div class="card-body">
          <file-upload
            @upload-finished="onUploadFinished"
          />
        </div>
      </div>
      <div class="col-md-12 gx-0">
        <file-list 
          ref="fileListRef"
        />
      </div>
    </div>
  </div>
</template>

<script>
import FileUpload from '@/components/FileBrowser/Upload/FileUpload.vue';
import FileList from '@/components/FileBrowser/File/FileList.vue';

export default {
  components: {
    FileUpload,
    FileList
  },

  methods: {
    onUploadFinished() {
      if (this.$refs.fileListRef) {
        this.$refs.fileListRef.reload();
      }
    }
  }
}
</script>