<template>
  <input 
    id="file-upload" 
    class="d-none" 
    type="file"
    multiple 
    :accept="acceptedFileTypes"
    @change="handleLocalFileUpload"
  />
  <label 
    class="btn btn-primary mx-auto w-50 d-flex align-items-center justify-content-center" 
    for="file-upload"
  >
    Upload bestanden
    <i
      class="material-icons-round ms-2"
    >
      upload
    </i>
  </label>

  <div class="file-divider">
    <p>
      <span>{{ filesLabel }}</span>
    </p>
  </div>

  <uploaded-file
    v-for="file in files"
    :key="file.id"
    :file="file.data"
    @upload-finished="() => onUploadFinished(file)"
  />
</template>

<script>
import { toRaw } from 'vue';

import UploadedFile from './UploadedFile.vue';

export default {
  components: {
    UploadedFile
  },

  emits: [
    'upload-finished'
  ],

  data() {
    return {
      files: []
    };
  },

  computed: {
    acceptedFileTypes() {
      let fileTypes = [
        "application/pdf",
        "text/csv",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "image/jpeg",
        "image/png",
        "image/svg+xml"
      ];

      return fileTypes.join(',');
    },

    filesLabel() {
      let label = `${this.files.length} bestand`;
      if (this.files.length != 1) label += 'en';

      return label;
    }
  },

  methods: {
    handleLocalFileUpload(event) {
      for(let file of event.target.files) this.files.push({
        data: file,
        uploadFinished: false
      });
    },

    onUploadFinished(file) {
      let fileIndex = this.files.findIndex(f => toRaw(f.data) === file.data);
      if (fileIndex < 0) return;

      this.files[fileIndex] = { ...this.files[fileIndex], uploadFinished: true };
      this.checkUploadState();
    },

    checkUploadState() {
      for(const file of this.files) {
        if (!file.uploadFinished) {
          return;
        }
      }
      
      this.$emit('upload-finished');
    }
  }
};

</script>

<style lang="scss">
.file-divider {
  border-bottom: 1px dotted black;
  margin-bottom: 15px;

  p {
    text-align: center;
    margin-bottom: -0.8em;

    span { 
      background: #fff;
      padding-inline: 10px;
    }
  }
}
</style>