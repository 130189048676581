<template>
  <div>
    <a 
      v-if="!isEditing"
      :href="file.url"
      target="_blank"
      rel="noreferrer noopener"
    >
      {{ updatedTitle ?? file.title }}
    </a>

    <div
      v-if="isEditing"
      class="d-flex align-items-center pe-4"
    >
      <material-input
        v-model="updatedTitle"
        :placeholder="file.title"
        class="w-100"
      />

      <material-button
        v-if="!isWorking"
        class="ms-1 p-1 pe-2"
        size="md"
        :disabled="!updatedTitle"
        @click.prevent="onSaveButtonPressed"
      >
        <i 
          class="material-icons-round"
        >
          save
        </i>
      </material-button>

      <div
        v-if="isWorking"
        class="spinner-border spinner-border-sm mx-2"
        role="status"
      >
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>
</template>

<script>

import MaterialInput from '@/components/MaterialInput.vue';
import MaterialButton from '@/components/MaterialButton.vue';

export default {
  components: {
    MaterialButton,
    MaterialInput
  },

  props: {
    file: { type: Object, required: true },
    isEditing: { type: Boolean },
    isWorking: { type: Boolean }
  },

  emits: [
    'title-updated'
  ],

  data() { 
    return {
      updatedTitle: null
    }
  },

  methods: {
    onSaveButtonPressed() {
      this.$emit('title-updated', this.updatedTitle);
    }
  }
}
</script>