<template>
  <div class="card col-md-12 mb-4">
    <div class="card-body">
      <div
        v-if="loading"
        class="d-flex justify-content-center py-4"
      >
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      
      <div 
        v-if="!loading"
      >
        <div
          class="search-bar-container d-flex gap-2 mb-4"
        >
          <material-input 
            v-model="searchText"
            label="Zoeken..."
            @keyup="onSearchBarKeyUp"
          />

          <material-button
            class="icon-button p-0"
            @click.prevent="performSearch"
          >
            <material-icon
              icon="search"
              class="clear-search-text"
            />
          </material-button>

          <material-button
            v-if="searchText"
            class="icon-button p-0"
            color="danger"
            @click.prevent="clearSearchText"
          >
            <material-icon
              icon="cancel"
              class="clear-search-text"
            />
          </material-button>
        </div>

        <div class="headers" >
          <p>Titel</p>
          <p>Type</p>
        </div>

        <File
          v-for="file in currentResults.list"
          :key="file.id"
          :file="file"
          @updated="updatedFile => onUpdate(updatedFile)"
          @deleted="() => onDelete(file)"
          @renamed="updatedFile => onRename(updatedFile)"
        />

        <p
          v-if="!filesArePresent"
          class="text-center mb-0"
        >
          Geen bestanden gevonden
        </p>
      </div>
      
      <pager
          ref="pagerRef"
          class="mb-2"
          :page-size="currentDataSet.pageSize"
          :total-items="currentResults.count"
          @page-requested="onPageRequested"
          @page-size-changed="onPageSizeChanged"
      />
    </div>
  </div>
</template>

<script>

import File from '@/components/FileBrowser/File/File.vue';
import Pager from '@/components/FileBrowser/Pager.vue';
import MaterialButton from '@/components/MaterialButton.vue';
import MaterialIcon from '@/components/UI/MaterialIcon.vue';
import MaterialInput from '@/components/MaterialInput.vue';

import {
  removeFile,
  updateFileTitle
} from '@/api/providers/file-browser.js'

import { mapActions } from 'vuex';

import DocumentSet from '@/helpers/documents/document-set.js'
import SearchResultSet from '@/helpers/documents/search-result-set.js'

export default {
  components: {
    File,
    MaterialButton,
    MaterialIcon,
    MaterialInput,
    Pager
  },

  data() {
    return {
      documents: new DocumentSet([]),
      search: new SearchResultSet(),
      loading: false,
      searchText: ""
    };
  },

  computed: {
    currentDataSet() {
      return this.search.exists()
          ? this.search
          : this.documents;
    },

    currentResults() {
      return this.currentDataSet.results;
    },

    currentPageSize: {
      get() {
        return this.search.results.list
          ? this.search.pageSize
          : this.documents.pageSize;
      },

      set(value) {
        if (this.search.results.list != null) {
          this.search.setPageSize(value);
          return;
        }
      
        this.documents.setPageSize(25);
      }
    },

    filesArePresent() {
      return this.currentResults.list.length > 0;
    },

    totalFiles() {
      return this.currentResults.list.length;
    },
  },

  async mounted() {
    this.reload();
  },

  methods: {
    ...mapActions('notification', {
        addNotification: 'add',
      }
    ),

    async reload() {
      this.loading = true;
      
      await this.currentDataSet.getPage(0);

      this.loading = false;
    },

    async performSearch() {
      this.loading = true;

      await this.search.search(this.searchText);

      this.loading = false;
    },

    onSearchBarKeyUp(event) {
      if (event.code == "Enter") {
        this.performSearch();
      }
    },

    onPageRequested(page) {
      this.currentDataSet.getPage(page);
    },

    onPageSizeChanged(pageSize) {
      this.currentDataSet.setPageSize(Number(pageSize));

      if (this.$refs.pagerRef) {
        this.$refs.pagerRef.reset();
      }
    },
    
    onUpdate(file) {
      const fileIndex = this.currentResults.list.findIndex(f => f.id === file.id);
      if (fileIndex < 0) return;
      this.currentResults.list[fileIndex] = file;
    },

    async onDelete(file) {
      try {
        await removeFile(file.id);
        this.reload();
      }
      catch {
        this.addNotification({
          description: `Kon '${file.title}' niet verwijderen`,
          icon: {
            component: 'error',
          },
        });
      }
    },

    async onRename(file) {
      const fileToUpdate = file;
      fileToUpdate.state.isWorking = true;

      try {
        await updateFileTitle(
          fileToUpdate.id,
          fileToUpdate.title
        );

        fileToUpdate.state.isEditing = false;
      }
      catch {
        this.addNotification({
          description: `Kon titel van '${file.title}' niet aanpassen`,
          icon: {
            component: 'error',
          },
        });
      }

      fileToUpdate.state.isWorking = false;
    },

    async clearSearchText() {
      this.searchText = "";
      await this.search.clearResults();
    }
  },
};

</script>

<style scoped lang="scss">
th { 
  padding-left: 0;
}

th:last-child {
  text-align: right;
}

.search-bar-container {
  position: relative;
}

.search-bar-buttons {
  position: absolute;
  top: 8px;
  right: 10px;
  z-index: 99;

  i {
    cursor: pointer;
    width: 25px;
  }
}

.headers {
  p {
    display: inline-block;
    font-weight: 700;
  }

  p:nth-child(1) { width: 80%; }
  p:nth-child(2) { width: 20%; }

  a > i {
    height: 20px;
  }
}

.icon-button {
  width: 41.1px !important;
  height: 38px;
  box-sizing: border-box;
}
</style>
