<template>
  <div
    class="file mb-2"
  >
    <file-title-field
      :file=file
      :is-editing="isEditing"
      :is-working="isWorking"
      @title-updated="newTitle => onTitleUpdated(newTitle)"
    />

    <div>{{ file.fileType }}</div>

    <dropdown-menu
      :items="[
        {
          handler: () => openUrl(file.url),
          name: 'Openen',
        },
        {
          handler: onEditPressed,
          name: 'Bewerken',
        },
        {
          handler: onDeletePressed,
          name: 'Verwijderen',
        },
      ]"
    />
  </div>
</template>

<script>
import { createYesNoSweetAlert } from '@/helpers/sweetalert';
import DropdownMenu from '../../UI/DropdownMenu.vue';
import FileTitleField from './FileTitleField.vue';

export default {
  components: {
    DropdownMenu,
    FileTitleField
  },

  props: {
    file: { type: Object, required: true }
  },

  emits: [
    'deleted',
    'updated',
    'renamed'
  ],

  data() {
    return {
      title: this.file.title,
    }
  },

  computed: {
    isEditing() {
      return this.file?.state?.isEditing;
    },
    isWorking() {
      return this.file?.state?.isWorking;
    }
  },

  methods: {
    onDeletePressed() {
      createYesNoSweetAlert(
        `Bestand ${this.file.title} verwijderen?`,
        () => this.$emit('deleted', this.file)
      );
    },

    onEditPressed() { 
      this.$emit('updated', { 
        ...this.file, 
        state: { 
          ...this.file.state,
          isEditing: true
        }
      });
    },

    onTitleUpdated(title) {
      this.$emit('renamed', { ...this.file, title });
    },

    openUrl(url) { 
      window.open(url, '_blank'); 
    }
  },
}
</script>

<style scoped lang="scss">
.file {
  display: flex;
  justify-content: space-between;

  & > div {
    display: inline-block;
  }

  & > div:nth-child(1) {
    width: 80%;
  }

  & > div:nth-child(2) {
    width: 20%;
  }

  a > i {
    height: 20px;
  }
}

input { 
  width: 100% !important; background: red;
}
</style>