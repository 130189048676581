import DocumentSet from "./document-set.js"

import { getSearchResultsPage } from '@/api/providers/file-browser.js'

class SearchResultSet extends DocumentSet {
  constructor(searchText = "", pageSize = 25) {
    super(null, pageSize);
    this.searchText = searchText;
  }

  search(searchText) {
    this.searchText = searchText;

    if (this.searchText === "") {
      this.clearResults();
      return;
    }

    this.getPage(0);
  }

  clearResults() {
    this.results.list = null;
  }

  async getPage(page) {
    try {
      let data = await getSearchResultsPage(this.searchText, page, this.pageSize);
      
      this.results.list = data.documents.map(d => this.injectDocumentStatefields(d));
      this.results.count = data.totalResults ?? 0;
    }
    catch {
      this.results.list = [];
      this.results.count = 0;
    }
  }
}

export default SearchResultSet;