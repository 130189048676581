import { 
    getRequest, 
    postRequest, 
    patchRequest, 
    deleteRequest 
} from '..';

export const getPage = (page, pageSize) => 
  getRequest(`/documents/${page}/${pageSize}`);

export const getSearchResultsPage = (text, page, pageSize) =>
  postRequest(`/documents/search`, { text, page, pageSize });

export const removeFile = (id) => 
  deleteRequest(`/documents/${id}`);

export const uploadFile = (title, fileName, base64Data) =>
  postRequest('/documents', { title, fileName, base64Data });

export const updateFileTitle = (id, title) =>
  patchRequest('/documents', { id, title });