<template>
  <i
    class="material-icons-round"
  >
    {{ icon }}
  </i>
</template>

<script>
export default {
  props: {
    icon: { type: String, required: true }
  }
}
</script>

<style scoped>
i {
  vertical-align: middle;
}
</style>