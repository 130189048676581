<template>
  <div
    v-if="isInViewMode"
    class="uploaded-file mb-2"
    @click.prevent="switchToEditMode"
  >
    <span
      class="file-name pe-2"
    >
      {{ fileTitle }}
    </span>

    <div
      v-if="isLoading"
      class="spinner-border spinner-border-sm"
      role="status"
    >
      <span class="sr-only">Loading...</span>
    </div>

    <div 
      v-if="isFinished"
    >
      <span>
        <i 
          class="material-icons-round"
        >
          done
        </i>
      </span>
    </div>

    <div 
      v-if="hasFailed"
    >
      <span>
        <i 
          class="material-icons-round"
          :title="error"
        >
          error
        </i>
      </span>
    </div>
  </div>

  <div
    v-if="isInEditMode"
    class="d-flex align-items-center mb-2"
  >
    <material-input
      v-model="title"
      :placeholder="fileTitle"
    />

    <material-button
      v-if="isIdle"
      class="ms-1 p-1 pe-2"
      size="md"
      :disabled="!uploadedFileId"
      @click.prevent="saveTitle"
    >
      <material-icon
        icon="save"
      />
    </material-button>

    <div
      v-if="isSaving"
      class="spinner-border spinner-border-sm mx-2"
      role="status"
    >
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</template>

<script>

import { 
  uploadFile,
  updateFileTitle,
} from '@/api/providers/file-browser.js';

import MaterialButton from '../../MaterialButton.vue';
import MaterialInput from '@/components/MaterialInput.vue';
import MaterialIcon from '../../UI/MaterialIcon.vue';

const Mode = {
  View: 0,
  Edit: 1
};

const EditState = {
  Idle: 0,
  Saving: 1
}

const FileState = {
  Reading: 0,
  Success: 1,
  Error: 2
}

export default {
  components: {
    MaterialButton,
    MaterialInput,
    MaterialIcon
  },

  props: {
    file: { type: File, required: true }
  },

  emits: [
    'upload-finished'
  ],

  data() {
    return {
      mode: Mode.View,
      fileState: FileState.Reading,
      editState: EditState.Idle,
      reader: new FileReader(),
      title: "",
      uploadedFileId: null,
      uploadErrorText: null
    }
  },

  computed: {
    fileTitle() {
      if (this.title !== "") return this.title; 

      if (this.file.name === undefined) return "Bestand zonder naam";

      const extensionIndex = this.file.name.lastIndexOf('.');
      if (extensionIndex < 0) return this.file.name;

      const name = this.file.name.substring(extensionIndex, 0);
      return name != ""
        ? name
        : this.file.name;
    },
    isLoading() { return this.fileState == FileState.Reading; },
    isFinished() { return this.fileState == FileState.Success; },
    hasFailed() { return this.fileState == FileState.Error; },
    isInViewMode() { return this.mode == Mode.View; },
    isInEditMode() { return this.mode == Mode.Edit; },
    isIdle() { return this.editState == EditState.Idle; },
    isSaving() { return this.editState == EditState.Saving; }
  },

  async mounted()
  {
    this.reader.onload = this.onLoad;
	  this.reader.onerror = this.onError;
    this.reader.readAsDataURL(this.file);
  },

  methods: {
    async onLoad() {
      try {
        var uploadedDocument = await uploadFile(
          this.file.name, 
          this.file.name,
          this.reader.result
        );
        this.fileState = FileState.Success;

        this.uploadedFileId = uploadedDocument.id;
      }
      catch(error) {
        this.fileState = FileState.Error;
        this.error = error;
      }
      
      this.$emit('upload-finished');
    },

    onError() {
      this.fileState = FileState.Error;
      this.error = "Unable to upload file"
      console.error(this.reader.error);
    },

    switchToEditMode() { 
      if (this.fileState !== FileState.Success) return;
      this.mode = Mode.Edit; 
    },

    switchToViewMode() { this.mode = Mode.View; },

    async saveTitle()  {
      this.editState = EditState.Saving;
      
      try {
        await updateFileTitle(this.uploadedFileId, this.title);
        this.mode = Mode.View;
        this.editState = EditState.Idle;
      }
      catch {
        this.editState = EditState.Idle;
      }
    },
  }
}

</script>

<style scoped lang='scss'>
.uploaded-file {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 15px;
  border-radius: 15px;

  &:hover { 
    background: rgba(0,0,0,0.025);
    cursor: pointer;
  }

  .file-name {
    max-width: 90%;
    text-overflow: ellipsis;
    overflow: hidden;
    text-wrap: nowrap;
  }
}
</style>