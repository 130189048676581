<template>
  <div
    class="pager"
  >
    <div
      class="result-and-page-size"
    >
      <span>{{ resultCountLabel }}</span>

      <div
        class="page-size-selector"
      >
        <select
          class="form-select"
          @change="pageSizeChanged"
        >
          <option
            v-for="size in pageSizeOptions"
            :key="size"
            :selected="pageSizeIsSelected(size)"
          >
            {{ size }}
          </option>
        </select>
      </div>
    </div>
    
    <div
      class="pager-control"
    >
      <div 
        v-if="pageCount > 1"
        class="d-flex align-items-center justify-content-center"
      >
        <material-button
          class="p-2 mx-1"
          :disabled="isOnFirstPage"
          @click.prevent="goToFirstPage"
        >
          <material-icon
            icon="keyboard_double_arrow_left"
          />
        </material-button>
        <material-button
          class="p-2 mx-1"
          :disabled="hasNoPreviousPage"
          @click.prevent="goToPreviousPage"
        >
          <material-icon
            icon="keyboard_arrow_left"
          />
        </material-button>

        <div
          class="pages"
        >
          <material-icon
            v-for="page of pages"
            :key="page"
            :icon="getPageIcon(page)"
            @click.prevent="() => setPage(page)"
          />
        </div>
        
        <material-button
          class="p-2 mx-1"
          :disabled="hasNoNextPage"
          @click.prevent="goToNextPage"
        >
          <material-icon
            icon="keyboard_arrow_right"
          />
        </material-button>

        <material-button
          class="p-2 mx-1"
          :disabled="isOnLastPage"
          @click.prevent="goToLastPage"
        >
          <material-icon
            icon="keyboard_double_arrow_right"
          />
        </material-button>
      </div>
    </div>
  </div>
</template>

<script>
import MaterialButton from '@/components/MaterialButton.vue';
import MaterialIcon from '@/components/UI/MaterialIcon.vue';

export default {
  components: {
    MaterialButton,
    MaterialIcon
  },

  props: {
    pageSize: { type: Number, default: 25 },
    totalItems: { type: Number, required: true },
    pageSizeOptions: { type: Array, default: () => [ 10, 25, 50, 100 ]}
  },
  
  emits: [
    'page-requested',
    'page-size-changed'
  ],

  data() {
    return {
      currentPage: 0,
    }
  },

  computed: {
    pageCount() {
      return Math.ceil(this.totalItems / this.pageSize);
    },

    hasNoPreviousPage() {
      return this.currentPage === 0
    },

    hasNoNextPage() {
      return this.currentPage >= this.pageCount - 1;
    },

    isOnFirstPage() {
      return this.currentPage === 0
    },

    isOnLastPage() { 
      return this.currentPage === this.pageCount - 1;
    },

    resultCountLabel() { 
      return this.totalItems === 1
        ? '1 resultaat'
        : `${this.totalItems} resultaten`
    },

    pages() {
      return [ ...Array(this.pageCount).keys() ]
    }
  },

  methods: {
    getPageIcon(page) {
      return page === this.currentPage
        ? "radio_button_checked"
        : "radio_button_unchecked";
    },

    setPage(page) {
      this.currentPage = page;
      this.$emit('page-requested', this.currentPage);
    },

    reset() {
      this.currentPage = 0;
    },

    goToNextPage() {
      this.setPage(this.currentPage + 1)
    },

    goToPreviousPage() {
      this.setPage(this.currentPage - 1);
    },

    goToFirstPage() {
      this.setPage(0);
    },

    goToLastPage() {
      this.setPage(this.pageCount - 1);
    },

    pageSizeIsSelected(pageSize) {
      return pageSize === this.pageSize;
    },

    isExistingPageSizeOption(pageSize) {
      this.pageSizeOptions.includes(pageSize);
    },

    pageSizeChanged(event) {
      this.$emit('page-size-changed', event.target.value);
    }
  },
}
</script>

<style scoped lang="scss">

.pager,
.pager-control,
.result-and-page-size {
  display: flex;
}

.result-and-page-size  {
  flex-grow: 1;
}

.pager,
.pager-control {
  align-items: center;
  justify-content: space-between;
}

.result-and-page-size {
  justify-content: space-between;
  align-items: center;

  .page-size-selector {
    cursor: pointer;
    margin-right: 10px;

    select {
      text-align: right;
      padding-right: 20px;
      border: none;
    }
  }
}

i.material-icons-round:hover {
  cursor: pointer;
}
  
.pages i.material-icons-round:hover
{
  color: #e91e63;
}

@media(max-width: 991px)
{
  .pager {
    flex-direction: column-reverse;

    .result-and-page-size 
    {
      margin-top: 15px;

      span { 
        margin-right: 15px;
      }
    }
  }
}
</style>