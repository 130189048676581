import { getPage } from '@/api/providers/file-browser.js'

class DocumentSet {
  constructor(list, pageSize = 25) {
    this.results = {
        count: 0,
        list
    }

    this.pageSize = pageSize;
  }

  exists() { 
    return this.results.list != null;
  }

  async getPage(page) {
    try {
      let data = await getPage(page, this.pageSize);
      
      this.results.list = data.documents.map(d => this.injectDocumentStatefields(d));
      this.results.count = data.totalResults ?? 0;
    }
    catch {
      this.results.list = [];
      this.results.count = 0;
    }
  }

  async setPageSize(pageSize) {
    this.pageSize = pageSize;
    await this.getPage(0);
  }

  injectDocumentStatefields(document) {
    return { 
      ...document,
      state: {
        isEditing: false,
        isWorking: false
      }
    };
  }
}

export default DocumentSet